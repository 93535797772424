<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      okText="导出为图片"
      @ok="download"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <div style="max-height: 700px;overflow-y: scroll">
        <div ref="pi">
          <div class="item">
            <div><b>基本信息：</b></div>
            <a-row>
              <a-col :span="6">培训标题：<a>{{ record.train.train_title }}</a></a-col>
              <a-col :span="6">是否需要签到：
                <a-tag color="blue" v-if="record.is_sign===1">是</a-tag>
                <a-tag v-else>否</a-tag>
              </a-col>
            </a-row>
          </div>
          <div class="item">
            <div><b>培训内容：</b></div>
            <div v-html="record.train.train_content" style=""></div>
          </div>
          <div class="item">
            <div><b>签到信息：</b></div>
            <div class="table">
              <div class="tr">
                <div class="td fw600">签到人</div>
                <div class="td fw600">签名</div>
                <div class="td fw600">签到时间</div>
              </div>
              <div class="tr" v-for="item in record.sign_list">
                <div class="td">
                  <div v-if="item.personnel">
                    {{ item.personnel.personnel_name }}
                    <a-tag color="blue" v-if=" item.personnel.personnel_type===0">司机</a-tag>
                    <a-tag color="orange" v-else>押运员</a-tag>
                  </div>
                </div>
                <div class="td">
                  <img style="height: 100%" v-if="item.sign_file" :src="item.sign_file.external_url">
                </div>
                <div class="td">{{ item.create_time }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>

import html2canvas from "html2canvas";
import {UploadFile, UploadImage} from '@/components'

export default {
  components: {
    UploadFile,
    UploadImage
  },
  data() {
    return {
      // 标签页索引
      tabKey: 0,
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 10
      },
      // 输入框布局属性
      wrapperCol: {
        span: 14
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前记录
      record: {},

      statusArray: ['未入职', '已入职']
    }
  },
  methods: {
    /**
     * 显示对话框
     */
    open(item) {
      console.log(item)
      this.record = item
      this.title = '查看详情'
      this.visible = true
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
    },
    //截图
    download() {
      html2canvas(this.$refs.pi, {
        useCORS: true // 开启 CORS 功能
      }).then(canvas => {
        // 转成图片，生成图片地址
        var imgUrl = canvas.toDataURL('image/png')
        var eleLink = document.createElement('a')
        eleLink.href = imgUrl // 转换后的图片地址
        eleLink.download = 'pictureName'
        // 触发点击
        document.body.appendChild(eleLink)
        eleLink.click()
        // 然后移除
        document.body.removeChild(eleLink)
      })
    },

  }
}
</script>
<style scoped lang="less">
.item {
  padding: 10px;
  border: 1px #e8e8e8 solid;
  border-radius: 10px;
  line-height: 30px;
  margin-bottom: 10px;
}

.cert_cover {
  .image {
    height: 100px;
    margin-right: 5px;
    border-radius: 5px;
    border: 1px #e8e8e8 solid;
  }
}

.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d9d9d9 solid;
    height: 40px;
    line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      height: 40px;
      width: 100%;
      text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        border: none;
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .fw600 {
    font-weight: 600;
  }
}

</style>
