<template>
  <a-card :bordered="false">
    <div class="card-title">培训记录</div>
    <div class="table-operator">
      <a-button  v-if="!isEmpty(contract)" v-action:pxjl_add type="primary" icon="plus" @click="handleAdd">增加培训记录</a-button>
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <template v-if="!isEmpty(contract)">
      <s-table
          ref="table"
          rowKey="train_log_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
      >
        <div slot="personnel_type" slot-scope="text, item">
          <a-tag color="blue" v-if="item.personnel_type==0">司机</a-tag>
          <a-tag color="orange" v-if="item.personnel_type==1">押运员</a-tag>
        </div>
        <div slot="log_type" slot-scope="text, item">
          <a-tag color="blue" v-if="text===0">定期培训</a-tag>
          <a-tag v-if="text===1">新人培训</a-tag>
        </div>
        <div slot="is_sign" slot-scope="text, item">
          <a-tag v-if="text===0">否</a-tag>
          <a-tag color="blue" v-if="text===1">是</a-tag>
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <a v-action:pxjl_show @click="handleDetails(item)">查看详情</a>
          <a v-action:pxjl_delete @click="handleDelete(item)">删除</a>
        </div>
      </s-table>
      <SaveForm :train_id="contract.train_id" ref="SaveForm" @handleSubmit="handleRefresh"/>
    </template>
    <template v-else>
      <div style="height: 500px;display: flex;align-items: center;justify-content: center">
        <a-empty>
          <span slot="description"> 请在左侧培训内容中点击对应内容查看指定的培训记录 </span>
        </a-empty>
      </div>
    </template>
    <ShowDetails
        ref="ShowDetails"
    />
  </a-card>
</template>

<script>
import * as Api from '@/api/train/trainLog'
import {STable} from '@/components'
import SaveForm from './modules/SaveForm'
import SearchForm from './modules/SearchForm'
import ShowDetails from './modules/ShowDetails.vue'
import {isEmpty} from "@/utils/util";

export default {
  name: 'Index',
  components: {
    STable,
    SaveForm,
    SearchForm,
    ShowDetails
  },
  data() {
    return {
      // 查询参数
      queryParam: {},
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        {
          title: '培训类型',
          dataIndex: 'log_type',
          scopedSlots: {customRender: 'log_type'}
        }, {
          title: '是否签到',
          dataIndex: 'is_sign',
          scopedSlots: {customRender: 'is_sign'}
        },
        {
          title: '创建时间',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      },

      contract: {}
    }
  },
  created() {

  },
  methods: {
    isEmpty,
    setData(item) {
      this.contract = item
      this.queryParam.train_id = item.train_id
      this.handleRefresh(true)
    },
    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },
    handleDetails(item) {
      this.$refs.ShowDetails.open(item)

    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({train_log_id: item['train_log_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$nextTick(() => {
        this.$refs.table.refresh(bool)
      })
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
