<template>
  <a-modal
      :title="title"
      :width="520"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="参与培训员工" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a @click="selectAll(0)">全选驾驶员</a>
          <a @click="selectAll(1)" style="margin-left: 20px">全选押运员</a>
          <a-select
              allowClear
              mode="multiple"
              show-search
              :filter-option="filterOption"
              v-decorator="['personnel_ids',{rules: [{required: true, message: '请至少选择一位员工'}]}]"
              placeholder="请选择员工"
          >
            <a-select-option v-for="item in personnelList" :value="item.personnel_id">
              {{ item.personnel_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="培训类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group v-decorator="['log_type', {initialValue: 0, rules: [{required: true}]}]">
            <a-radio :value="0">定期培训</a-radio>
            <a-radio :value="1">新人培训</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="是否签到" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group v-decorator="['is_sign', {initialValue: 1, rules: [{required: true}]}]">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import * as Api from '@/api/train/trainLog'
import * as personnelApi from '@/api/personnel'
import _ from "lodash";
import UploadFile from "@/components/UploadFile/UploadFile.vue";

export default {
  props: ['train_id'],
  components: {UploadFile},
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",

      personnelList: [],
      driver_list: [],
      supercargo_list: []
    }
  },
  methods: {
    selectAll(item) {
      var personnel_ids
      if (item == 0) {
        personnel_ids = this.driver_list.map(item => item.personnel_id)
      } else {
        personnel_ids = this.supercargo_list.map(item => item.personnel_id)
      }
      this.form.setFieldsValue({personnel_ids})
    },
    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '增加培训记录'
      this.visible = true

      this.$nextTick(() => {
        this.getPersonnelList()
      })
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑培训记录'
      this.visible = true

      this.record = record
      this.setFieldsValue()
    },

    async getPersonnelList() {
      const {data: {driver_list, supercargo_list}} = await personnelApi.all()
      this.driver_list = driver_list
      this.supercargo_list = supercargo_list
      this.personnelList = [...driver_list, ...supercargo_list]
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['contract_name', 'contract_file_id', 'status'])
        setFieldsValue(data)
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        values.train_id = this.train_id
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({train_id: this.record['train_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
